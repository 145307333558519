import { Grid, Space } from "antd";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  border-radius: 2px;
  border: 1px solid;
  border-color: #4ed3b4;
  width: 100%;
  padding-top: 100%;
  min-width: 100px;
  min-height: 100px;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

type IImageData = {
  images: string[];
};

const { useBreakpoint } = Grid;

const ImageGrid = ({ images }: IImageData) => {
  const screens = useBreakpoint();
  return (
    <Space
      data-testid={screens.md ? "vertical" : "horizontal"}
      direction={screens.md ? "vertical" : "horizontal"}
      style={{ width: "100%", height: "100%" }}
    >
      {images.map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ImageContainer key={index}>
          <img src={image} alt={`${image}_${index}`} />
        </ImageContainer>
      ))}
    </Space>
  );
};

export default ImageGrid;
