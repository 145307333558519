import styled from "styled-components";
import Login from "../Login";

const LoginContainer = styled.div`
  flex: 50%;
  background-color: ${({ theme }) => theme.colors.bg.main};
  justify-content: center;
  align-items: center;
  padding: 30px 50px;
  text-align: center;
  height: 100vh;
  display: flex;
`;

const LoginPage = () => {
  return (
    <LoginContainer>
      <Login />
    </LoginContainer>
  );
};

export default LoginPage;
