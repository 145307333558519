import { Button, Col, Modal, Row, Upload, message } from "antd";
import React, { useRef, useState } from "react";
import TemplateSettingsForm from "./TemplateSettingsForm";
import { useTemplateContext } from "contexts/template-context";
import { TemplateState, default_settings as DefaultSettings, PostSettingsResponse } from "types/template";
import { Document, Page, View, PDFViewer, StyleSheet, Image } from "@react-pdf/renderer";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import useWidth from "hooks/useWidth";
import { pdfjs } from "react-pdf";
import { useAuthContext } from "contexts/auth-context";
import axios from "axios";
import { useReportContext } from "contexts/report-context";
import Spinner from "components/Shared/spinner";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type ITemplateDialogProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadButton = (
  <div>
    <Button icon={<UploadOutlined />}>Upload</Button>
    <div style={{ marginTop: 8 }}>Upload PDF Template</div>
  </div>
);

const SpinnerContainer = styled.div`
  flex: 100%;
  height: 801px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

type IPdfPreviewProps = {
  templateState: TemplateState;
};

const style: React.CSSProperties = { padding: "18px 0 20px" };

const PdfPreview = ({ templateState }: IPdfPreviewProps) => {
  const { layoutSettings, pdfInfo } = templateState;
  const { pdfImageUrl } = pdfInfo;
  const { header, footer, margin } = layoutSettings;
  const target = useRef(null);
  const width = useWidth({ target });

  const styles = StyleSheet.create({
    page: {
      paddingLeft: margin,
      paddingRight: margin,
      width: width as number,
    },
    header: {
      minHeight: header,
      alignItems: "center",
      justifyContent: "center",
      border: 4,
      borderColor: "#4ED3B4",
    },
    body: {
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
      border: 4,
      borderColor: "#9577FF",
    },
    footer: {
      minHeight: footer,
      alignItems: "center",
      justifyContent: "center",
      border: 4,
      borderColor: "#4ED3B4",
    },
    backgroundImage: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  });

  return (
    <div style={{ height: "98%", width: "500px", overflow: "hidden" }} ref={target}>
      <PDFViewer height="100%" width={width || "100%"} style={{ border: "none" }} showToolbar={false}>
        <Document>
          <Page size="A4" style={styles.page}>
            {pdfImageUrl && <Image src={pdfImageUrl} style={styles.backgroundImage} fixed />}
            <View style={styles.header} fixed />
            <View style={styles.body} />
            <View style={styles.footer} fixed />
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const checkPdfPage = (pdf: pdfjs.PDFDocumentProxy) => {
  const pdfPages = pdf.numPages;
  if (pdfPages > 1) {
    message.error("PDF should contain only one page.");
    return true;
  }
  return false;
};

const checkPdfDimensions = async (pdf: pdfjs.PDFDocumentProxy) => {
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  const A4WidthInPoints = 595;
  const A4HeightInPoints = 842;

  // Check if the dimensions of the page fall within a range of ± 1 around the A4 dimensions
  if (
    !(A4WidthInPoints - 1 <= viewport.width && viewport.width <= A4WidthInPoints + 1) ||
    !(A4HeightInPoints - 1 <= viewport.height && viewport.height <= A4HeightInPoints + 1)
  ) {
    message.error("PDF Page is not A4");
    return false;
  }
  return true;
};

const pdf2Image = async (pdf: pdfjs.PDFDocumentProxy) => {
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");

  if (!canvasContext) {
    throw new Error("Could not create 2d context.");
  }

  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const makeImage = (): Promise<string> => {
    return new Promise(resolve => {
      page
        .render({
          canvasContext,
          viewport,
        })
        .promise.then(() => {
          resolve(canvas.toDataURL("image/png"));
        });
    });
  };

  const pdfImageUrl = await makeImage();
  return { pdfImageUrl };
};

const TemplateDialog = ({ isOpen, setIsOpen }: ITemplateDialogProps) => {
  const { templateState, updateTemplateState } = useTemplateContext();
  const { updateReportState, reportState } = useReportContext();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { pdfInfo, layoutSettings, defaultSettings } = templateState;
  const { file: templateFile } = pdfInfo;
  const { authToken } = useAuthContext();

  const handlePdfUpload = async (file: RcFile) => {
    const pdf = await pdfjs.getDocument(await file.arrayBuffer()).promise;

    const isNotOnePage = checkPdfPage(pdf);

    if (isNotOnePage) {
      return false;
    }

    const isPdfDimensionsCorrect = await checkPdfDimensions(pdf);

    if (!isPdfDimensionsCorrect) {
      return false;
    }

    const { pdfImageUrl } = await pdf2Image(pdf);

    updateTemplateState({
      pdfInfo: {
        file,
        pdfImageUrl,
      },
      layoutSettings,
      defaultSettings,
    });
    return false;
  };

  const handleOk = async () => {
    setIsSaving(true);
    const body = new FormData();
    if (templateFile) {
      body.append("template_file", templateFile);
    }

    const templateDefaultSettings: DefaultSettings = {
      radiologist_name: defaultSettings.radiologistName,
      pacs_ip_address: defaultSettings.pacsIpAddress,
      pacs_port: defaultSettings.pacsPort,
    };

    body.append("template_layout_settings", JSON.stringify(layoutSettings));
    body.append("template_default_settings", JSON.stringify(templateDefaultSettings));

    const headers = {
      Authorization: authToken,
    };

    const { data } = await axios.post("/api/settings", body, { headers });
    const {
      layout_settings: layoutSettingsResp,
      template,
      default_settings: defaultSettingsResp,
    }: PostSettingsResponse = data;

    if (data) {
      const imageUrl = `data:image/png;base64,${template}`;
      updateTemplateState({
        pdfInfo: {
          pdfImageUrl: imageUrl,
          file: templateFile,
        },
        layoutSettings: layoutSettingsResp,
        defaultSettings: {
          radiologistName: defaultSettingsResp.radiologist_name,
          pacsIpAddress: defaultSettingsResp.pacs_ip_address,
          pacsPort: defaultSettingsResp.pacs_port,
        },
      });

      updateReportState({
        ...reportState,
        doctorInfo: {
          radiologistDoctor: defaultSettingsResp.radiologist_name,
        },
      });
      setIsSaving(false);
    }

    setIsOpen(false);
  };
  return (
    <Modal
      title="PDF Template Settings"
      open={isOpen}
      okText="Save"
      onCancel={() => setIsOpen(false)}
      onOk={handleOk}
      width={1500}
    >
      <Row gutter={16}>
        {isSaving ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            <Col span={9}>
              <Col span={24} style={style}>
                <Upload.Dragger accept=".pdf" showUploadList={false} height={300} beforeUpload={handlePdfUpload}>
                  {UploadButton}
                </Upload.Dragger>
              </Col>
              <Col span={24}>
                <h3>Settings</h3>
                <TemplateSettingsForm initialFormValues={templateState} />
              </Col>
            </Col>
            <Col span={15} style={{ justifyContent: "center", alignContent: "center", display: "flex" }}>
              <PdfPreview templateState={templateState} />
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
};

export default TemplateDialog;
