import React, { useCallback, useContext, useMemo, useState } from "react";
import axios from "axios";
import { User } from "../types/users";

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error?: string;
  authToken?: string;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  loading: false,
};

interface AuthContextType extends AuthState {
  login: ({ token }: User) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>({
  ...initialAuthState,
  login: async () => {},
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const sessionToken = sessionStorage.getItem("token");
  const [state, setState] = useState<AuthState>({
    loading: false,
    isAuthenticated: !!sessionToken,
    ...(sessionToken && { authToken: sessionToken }),
  });

  const setCookie = useCallback(async ({ token }: User) => {
    try {
      await axios.post(
        "/api/set-cookie",
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: token,
          },
        },
      );
      window.loadBrowserSDK();
    } catch (error) {
      console.error("Failed to set cookie with Axios:", error);
    }
  }, []);

  const login = useCallback(
    async ({ token }: User) => {
      setState({ loading: true, isAuthenticated: false });
      try {
        await axios.post(
          "/api/login",
          {},
          {
            params: {
              token,
            },
          },
        );
        sessionStorage.setItem("token", token);
        setCookie({ token });
        setState({ loading: false, isAuthenticated: true, authToken: token });
      } catch (e) {
        setState({ loading: false, isAuthenticated: false, error: "Invalid token." });
      }
    },
    [setState],
  );

  const contextValue = useMemo(() => {
    return {
      ...state,
      login,
    };
  }, [state, login]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
};

export { AuthContextProvider, useAuthContext, AuthContext };
