import { Divider, Form, Input, Select, Typography } from "antd";
import styled from "styled-components";
import { ReportState } from "types/ReportInfo";
import { useReportContext } from "contexts/report-context";
import DatePickerField from "components/UI/DatePickerField";
import { useEffect } from "react";

const SidePanelContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.light};
  height: 100%;
  padding: 1.25rem 0.75rem 0px;
  overflow-y: auto;
`;

type FormValues = ReportState;

interface ReportFormProps {
  initialValues: FormValues;
}

const { Title } = Typography;
const { Option } = Select;

const ReportForm = ({ initialValues }: ReportFormProps) => {
  const { updateReportState } = useReportContext();
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const handleFormChange = (_changedValues: Partial<FormValues>, allValues: FormValues) => {
    updateReportState(allValues); // update the report state with current form values
  };

  return (
    <SidePanelContainer>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        style={{ maxWidth: 600 }}
        layout="vertical"
        initialValues={initialValues}
      >
        <Title level={4} style={{ textAlign: "center" }}>
          Patient Information
        </Title>
        <Form.Item
          label="INS"
          // rules={[{ required: true, message: "Patient name is required*", type: "string" }]}
          name={["patientInfo", "ins"]}
        >
          <Input
            maxLength={50}
            placeholder="Enter INS"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item>
        <Form.Item
          label="Name"
          // rules={[{ required: true, message: "Patient name is required*", type: "string" }]}
          name={["patientInfo", "patientName"]}
        >
          <Input
            maxLength={50}
            placeholder="Enter Patient Name"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item>
        <Form.Item
          label="Age"
          // rules={[{ required: true, message: "Patient Age is required*", type: "string" }]}
          name={["patientInfo", "patientAge"]}
        >
          <Input
            placeholder="Enter Patient Age"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item>
        <Form.Item
          name={["patientInfo", "patientSex"]}
          label="Sex"
          // rules={[{ required: true, message: "Patient Sex is required*", type: "string" }]}
        >
          <Select placeholder="Select Patient Sex" allowClear>
            <Option value="M">M</Option>
            <Option value="F">F</Option>
            <Option value="O">O</Option>
          </Select>
        </Form.Item>
        <Divider />

        <Title level={4} style={{ textAlign: "center" }}>
          Doctors Name
        </Title>
        <Form.Item
          // rules={[{ required: true, message: "Please enter name of Radiologist", type: "string" }]}
          name={["doctorInfo", "radiologistDoctor"]}
          label="Radiologist"
        >
          <Input
            placeholder="Enter Radiologist Name"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item>
        <Form.Item
          name={["doctorInfo", "requestingDoctor"]}
          // rules={[{ required: true, message: "Please enter name of Requesting doctor", type: "string" }]}
          label="Requesting"
        >
          <Input
            placeholder="Enter Requesting Doctor Name"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item>
        {/* <Form.Item
          // rules={[{ required: true, message: "Please enter name of doctor, who validates the report", type: "string" }]}
          name={["doctorInfo", "validationDoctor"]}
          label="Validate"
        >
          <Input
            placeholder="Enter Validating Doctor Name"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item> */}
        <Divider />
        <Title level={4} style={{ textAlign: "center" }}>
          Technique
        </Title>
        {/* <Form.Item
          name={["reportTechnique", "appareil"]}
          // rules={[{ required: true, message: "Please appareil technique.", type: "string" }]}
          label="Appareil"
        >
          <Input
            placeholder="Enter Appareil"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item> */}
        <Form.Item
          name={["reportTechnique", "dose"]}
          // rules={[{ required: true, message: "Please dose technique.", type: "string" }]}
          label="Dose"
        >
          <Input
            placeholder="Enter Dose"
            style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
          />
        </Form.Item>
        <Divider />
        <Title level={4} style={{ textAlign: "center" }}>
          Report Dates
        </Title>
        <DatePickerField name="dateOfExam" label="Date of Exam" form={form} />
        <DatePickerField name="dateOfEntryOfCCR" label="Date of report" form={form} />
        {/* <DatePickerField name="dateOfValidation" label="Date of Validation" form={form} /> */}
      </Form>
    </SidePanelContainer>
  );
};

export default ReportForm;
