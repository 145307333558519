import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { AudioOutlined, SendOutlined, StopOutlined } from "@ant-design/icons";
// import { useAuthContext } from "contexts/auth-context";
// import axios from "axios";
// import useWhisper from "@milvue/use-whisper";

type IChatRequest = {
  generateResponse: (newQuestion: string) => Promise<boolean>;
  cancelRequest: () => void;
  loading: boolean;
  firstRequest: boolean;
};

const ChatContainer = styled.div`
  flex: 3%;
  width: 100%;
`;

const ChatInputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 6px;
  justify-content: center;
  position: relative;
`;

const ChatTextarea = styled(Input.TextArea)`
  flex: 1;
  font-size: 1.3em;
  padding: 10px;
  padding-right: 120px;
`;

const ChatButtonsContainer = styled.div`
  position: absolute;
  right: 2em;
  bottom: 0.5em;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  gap: 8px;
`;

function getCookieValue(name: string) {
  const matches = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return matches ? matches.pop() : null;
}

function checkCookie() {
  const cookieName = "NUSA_Guids";
  const cookieValue = getCookieValue(cookieName);
  return cookieValue !== null;
}

function getTextAreaValue() {
  const chatQuestiosElement = document.getElementById("chatQuestions") as HTMLTextAreaElement;
  return chatQuestiosElement.value;
}

const ChatSection = ({ generateResponse, cancelRequest, loading, firstRequest }: IChatRequest) => {
  // const { authToken } = useAuthContext();
  const [newQuestion, setNewQuestion] = useState("");

  // const onWhispered = async (file: File) => {
  //   const body = new FormData();
  //   body.append("audio_file", file);
  //   const headers = { Authorization: authToken };
  //   const response = await axios.post("/api/whisper", body, { headers });
  //   const { text } = await response.data;
  //   return text;
  // };

  // const { transcript } = useWhisper({
  //   onWhispered,
  //   streaming: true,
  //   nonStop: true,
  //   stopTimeout: 3000,
  //   autoTranscribe: true,
  // });

  useEffect(() => {
    const cookieExists = checkCookie();

    if (cookieExists) {
      window.loadBrowserSDK();
    }
  }, []);

  // useEffect(() => {
  //   if (transcript.text) {
  //     setNewQuestion(transcript.text);
  //   }
  // }, [transcript.text]);

  /**
   * For Nuance Speect-to-text
   */
  const handleNuanceMicClick = () => {
    window.NUSA_startRecording();
  };

  const requestResponse = async (text: string) => {
    window.NUSA_stopRecording();
    const responseSuccess = await generateResponse(text);
    if (responseSuccess) {
      setNewQuestion("");
    }
  };

  /**
   * For OpenAI Whisper
   */
  // const handleWhisperMicClick = () => {
  //   if (recording) {
  //     stopRecording();
  //     return;
  //   }
  //   startRecording();
  // };

  const requestHandler = async (text: string | null) => {
    // stopRecording();
    if (loading) {
      cancelRequest();
      return;
    }

    if (text && text.length > 0) {
      requestResponse(text);
      return;
    }

    if (newQuestion.length > 0) {
      requestResponse(newQuestion);
      return;
    }

    const nuanceSpeechKitResponseValue = getTextAreaValue();
    if (nuanceSpeechKitResponseValue.length > 0) {
      requestResponse(nuanceSpeechKitResponseValue);
    }
  };

  const handleTranscriptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewQuestion(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      window.NUSA_stopRecording();
      const nuanceSpeechKitResponseValue = getTextAreaValue();
      event.preventDefault();
      requestHandler(nuanceSpeechKitResponseValue);
    }
  };

  return (
    <ChatContainer>
      <ChatInputContainer>
        <ChatTextarea
          id="chatQuestions"
          data-nusa-enabled="true"
          data-nusa-concept-name="chat"
          data-nusa-marker-container="hide_nuance_flame_icon"
          autoSize={{ maxRows: 3 }}
          style={{ resize: "none" }}
          value={newQuestion}
          onKeyDown={handleKeyDown}
          onChange={handleTranscriptChange}
          placeholder="Type your message..."
        />
        {/* Trick to hide the default flame icone */}
        <div id="hide_nuance_flame_icon" style={{ display: "none" }} />
        <ChatButtonsContainer>
          <Button
            name="toggleRecordBtn"
            shape="round"
            // danger={recording}
            icon={<AudioOutlined />}
            size="small"
            onClick={handleNuanceMicClick}
          />
          <Button
            name="sendMessageBtn"
            shape="round"
            disabled={firstRequest}
            icon={loading && !firstRequest ? <StopOutlined /> : <SendOutlined />}
            size="small"
            onClick={() => requestHandler(newQuestion)}
          />
        </ChatButtonsContainer>
      </ChatInputContainer>
    </ChatContainer>
  );
};

export default ChatSection;
