import React from "react";
import { ConfigProvider, theme } from "antd";

type AntdProviderProps = {
  children: React.ReactNode;
};

function AntdProvider({ children }: AntdProviderProps) {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#4ED3B4", // milvue green
          colorSplit: "#4ED3B4",
          colorPrimaryBg: "#24303E", // milvue dark_grey
          colorBgContainer: "#24303E", // milvue dark_grey
          colorSuccess: "#4ED3B4", // milvue green
          colorFillSecondary: "#9577FF", // milvue purple
          colorText: "#F4F4F4", // milvue light_grey/white
          colorPrimaryText: "#F4F4F4", // milvue light_grey/white
          fontFamily: "HelveticaNeueRegularMilvue",
          colorBorder: "#4ED3B4",
          colorBgLayout: "#24303E",
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default AntdProvider;
