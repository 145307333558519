import { TemplateState } from "types/template";
import { Form, Input, InputNumber, Typography } from "antd";
import { useTemplateContext } from "contexts/template-context";
import styled from "styled-components";
import { useEffect } from "react";

type FormValues = TemplateState;

const TemplateFormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.main};
  padding: 1.25rem 0.75rem 0.75rem;
`;

type TemplateSettingsFormProps = {
  initialFormValues: FormValues;
};

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 16 },
};

const { Title } = Typography;

const TemplateSettingsForm = ({ initialFormValues }: TemplateSettingsFormProps) => {
  const { updateTemplateState, templateState } = useTemplateContext();
  const [form] = Form.useForm<FormValues>();
  const { pdfInfo } = templateState;

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [initialFormValues, form]);

  const handleFormChange = (_changedValues: Partial<FormValues>, allValues: FormValues) => {
    updateTemplateState({
      layoutSettings: allValues.layoutSettings,
      pdfInfo,
      defaultSettings: allValues.defaultSettings,
    });
  };

  return (
    <TemplateFormContainer>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        labelAlign="left"
        {...layout}
        style={{ maxWidth: 600 }}
        layout="horizontal"
        initialValues={initialFormValues}
      >
        <Title level={4}>Layout Settings</Title>
        <Form.Item label="Header" name={["layoutSettings", "header"]}>
          <InputNumber placeholder="Enter Header Size" />
        </Form.Item>

        <Form.Item label="Footer" name={["layoutSettings", "footer"]}>
          <InputNumber placeholder="Enter Footer Size" />
        </Form.Item>

        <Form.Item label="Margin" name={["layoutSettings", "margin"]}>
          <InputNumber placeholder="Enter Margin Size" />
        </Form.Item>
        <Title level={4}>Default Settings</Title>
        <Form.Item labelCol={{ span: 6 }} label="Radiologist Name" name={["defaultSettings", "radiologistName"]}>
          <Input placeholder="Enter Radiologist Name" />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="PACS IP Address" name={["defaultSettings", "pacsIpAddress"]}>
          <Input placeholder="Enter PACS IP Address" />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="PACS Port" name={["defaultSettings", "pacsPort"]}>
          <Input placeholder="Enter PACS Port" />
        </Form.Item>
      </Form>
    </TemplateFormContainer>
  );
};

export default TemplateSettingsForm;
