import { DatePicker, DatePickerProps, Form, FormInstance } from "antd";
import { useReportContext } from "contexts/report-context";
import { ReportDateTypes, ReportState } from "types/ReportInfo";

type IDatePickerFieldProps = {
  name: ReportDateTypes;
  label: string;
  form: FormInstance<ReportState>;
};

const dateFormat = "DD/MM/YYYY";

const DatePickerField = ({ label, name, form }: IDatePickerFieldProps) => {
  const { updateReportState } = useReportContext();

  const handleDateChange: DatePickerProps["onChange"] = newDate => {
    if (!newDate) {
      return;
    }
    let formValues = form.getFieldsValue();
    formValues = {
      ...formValues,
      reportDates: {
        ...formValues.reportDates,
        [name]: newDate,
      },
    };
    form.setFieldsValue(formValues);
    updateReportState(formValues);
  };

  return (
    <Form.Item
      name={["reportDates", name]}
      rules={[{ required: true, message: `${label} is required` }]}
      label={`${label}:`}
    >
      <DatePicker format={dateFormat} onChange={handleDateChange} />
    </Form.Item>
  );
};

export default DatePickerField;
