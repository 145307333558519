import { useState, useLayoutEffect } from "react";
import useResizeObserver from "@react-hook/resize-observer";

type IUseWidthProps = {
  target: any;
};
const useWidth = ({ target }: IUseWidthProps) => {
  const [width, setWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    setWidth(target.current.getBoundingClientRect().width);
  }, [target]);

  useResizeObserver(target, entry => setWidth(entry.contentRect.width));
  return width;
};

export default useWidth;
