import dayjs from "dayjs";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ReportState } from "types/ReportInfo";

const initialReportState: ReportState = {
  patientInfo: {
    ins: "",
    patientName: "",
    patientSex: "M",
    patientAge: "",
  },
  doctorInfo: {
    radiologistDoctor: "",
  },
  reportDates: {
    dateOfExam: dayjs(),
    dateOfEntryOfCCR: dayjs(),
  },
  reportTechnique: {
    appareil: "",
    dose: "",
  },
};

interface ReportContextType {
  reportState: ReportState;
  updateReportState: (newReportValues: ReportState) => void;
}

const ReportContext = React.createContext<ReportContextType | undefined>(undefined);

type ReportContexttProviderProps = {
  children: React.ReactNode;
};

const ReportContextProvider = ({ children }: ReportContexttProviderProps) => {
  const [reportState, setReportState] = useState<ReportState>(initialReportState);

  const updateReportState = useCallback(
    (newReportValues: ReportState) => {
      setReportState(newReportValues);
    },
    [setReportState],
  );

  const contextValue = useMemo(() => {
    return {
      reportState,
      updateReportState,
    };
  }, [reportState, updateReportState]);

  return <ReportContext.Provider value={contextValue}>{children}</ReportContext.Provider>;
};

const useReportContext = () => {
  const context = useContext(ReportContext);
  if (context === undefined) {
    throw new Error("useReportContext must be used within a ReportContextProvider.");
  }

  return context;
};

export { ReportContextProvider, useReportContext, ReportContext };
