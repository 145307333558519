import React from "react";
import { ThemeProvider } from "styled-components";
import { styledMilvueTheme } from "../styles/styledMilvueTheme";

type RootProviderProps = {
  children: React.ReactNode;
};

function StyledThemeProvider({ children }: RootProviderProps) {
  return <ThemeProvider theme={styledMilvueTheme}>{children}</ThemeProvider>;
}

export default StyledThemeProvider;
