import { useCallback, useEffect, useMemo, useState } from "react";
import { withHistory } from "slate-history";
import { withReact, Slate, RenderElementProps, RenderLeafProps } from "slate-react";
import { CustomEditor, Descendant } from "types/CustomTypes";
import Editor from "components/UI/Editor";
import EditorElement from "./EditorElement";
import EditorLeaf from "./EditorLeaf";
import { createEditor } from "slate";

type SlateEditorProps = {
  reportContents: Descendant[];
};

const SlateEditor = ({ reportContents }: SlateEditorProps) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())) as CustomEditor, []);
  const [value, setValue] = useState<Descendant[]>(reportContents);
  useEffect(() => {
    setValue(reportContents);
  }, [reportContents]);

  const renderElement = useCallback((props: RenderElementProps) => <EditorElement {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <EditorLeaf {...props} />, []);

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={newValues => {
        setValue(newValues as Descendant[]);
      }}
    >
      <Editor editorValues={value} editor={editor} renderElement={renderElement} renderLeaf={renderLeaf} />
    </Slate>
  );
};

export default SlateEditor;
