import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/pages/LoginPage";
import ReportPage from "./components/pages/ReportPage";
import ProtectedRoutes from "./utils/ProtectedRoutes";

const routes = [
  {
    key: "Rootpage",
    element: "",
    path: "/",
    caseSensitive: true,
  },
  {
    key: "ReportPage",
    element: <ReportPage />,
    path: ":studyId",
    caseSensitive: false,
  },
];

const RoutesProvider = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        {routes.map(({ element, path, caseSensitive, key }) => (
          <Route key={key} element={element} path={path} caseSensitive={caseSensitive} />
        ))}
      </Route>
      <Route element={<LoginPage />} path="/login" />
    </Routes>
  );
};

export default RoutesProvider;
