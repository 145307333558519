import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { Descendant } from "types/CustomTypes";
import HelveticaNeueBold from "../../assets/fonts/HelveticaNeueBold.otf";
import HelveticaNeueLight from "../../assets/fonts/HelveticaNeueLight.otf";
import HelveticaNeueBoldItalic from "../../assets/fonts/HelveticaNeueBoldItalic.otf";
import HelveticaNeueLightItalic from "../../assets/fonts/HelveticaNeueLightItalic.otf";
import { TemplateState } from "types/template";
import { ReportState } from "types/ReportInfo";
import { isEmpty } from "utils/toolBarUtils";

Font.register({
  family: "Helvetica Neue",
  fonts: [
    { src: HelveticaNeueLight, fontWeight: "light", fontStyle: "normal" },
    { src: HelveticaNeueBold, fontWeight: "bold", fontStyle: "normal" },
    { src: HelveticaNeueBoldItalic, fontWeight: "bold", fontStyle: "italic" },
    { src: HelveticaNeueLightItalic, fontWeight: "light", fontStyle: "italic" },
  ],
});

type Ipdf = {
  contents: Descendant[];
  templateState: TemplateState;
  reportState: ReportState;
};

const insertElement = (array: Descendant[], toIndex: number, item: Descendant) => {
  array.splice(toIndex, 0, item);
};

const PDF = ({ contents, reportState, templateState }: Ipdf) => {
  const { reportTechnique, doctorInfo } = reportState;
  const { layoutSettings, pdfInfo } = templateState;
  const { pdfImageUrl } = pdfInfo;
  const { header, footer, margin } = layoutSettings;

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#f4f4f4",
      paddingLeft: margin,
      paddingRight: margin,
    },
    heading1: {
      fontSize: 20,
      textAlign: "center",
      top: 10,
      marginBottom: 2,
      textDecoration: "none",
      textTransform: "uppercase",
      fontFamily: "Helvetica Neue",
      width: "100%",
      backgroundColor: "#D8D8D8",
      padding: 8,
    },
    heading2: {
      fontSize: 16,
      marginTop: "0.5cm",
      marginBottom: 2,
      fontFamily: "Helvetica Neue",
    },
    paragraph: {
      fontSize: 12,
      marginBottom: 1,
      lineHeight: 1.2,
      fontFamily: "Helvetica Neue",
    },
    radiologistDoctorName: {
      marginBottom: 80,
      lineHeight: 1.2,
      fontSize: 12,
      fontWeight: "bold",
      fontFamily: "Helvetica Neue",
    },
    validationDoctorName: {
      marginBottom: 1,
      lineHeight: 1.2,
      fontSize: 12,
      fontWeight: "bold",
      fontFamily: "Helvetica Neue",
    },
    dateOfValidation: {
      marginBottom: 1,
      lineHeight: 1.2,
      fontSize: 12,
      fontFamily: "Helvetica Neue",
    },
    watermark: {
      position: "absolute",
      margin: "auto",
      fontSize: 65,
      top: 330,
      left: 60,
      fontWeight: "bold",
      transform: "rotate(-56deg)",
      opacity: 0.5,
      color: "gray",
      fontFamily: "Helvetica Neue",
      zIndex: 2,
    },
    header: {
      minHeight: header,
    },
    body: {
      flex: 1,
      flexGrow: 1,
      padding: 8,
    },
    footer: {
      minHeight: footer,
    },
    backgroundImage: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 5,
    },
    tableCell1: {
      fontSize: 12,
      marginBottom: 1,
      lineHeight: 1.2,
      fontFamily: "Helvetica Neue",
      width: "50%",
    },
    tableCell2: {
      fontSize: 12,
      marginBottom: 1,
      lineHeight: 1.2,
      textAlign: "left",
      fontFamily: "Helvetica Neue",
      width: "50%",
    },
  });

  interface ITableRowProps {
    child1: string;
    child2: string;
  }
  const TableRow = ({ child1, child2 }: ITableRowProps) => {
    if (isEmpty(child1) && isEmpty(child2)) return null;

    return (
      <View style={styles.tableRow}>
        {!isEmpty(child1) && <Text style={styles.tableCell1}>{child1}</Text>}
        {!isEmpty(child2) && <Text style={styles.tableCell2}>{child2}</Text>}
      </View>
    );
  };

  // copy the contents array to prevent modifying original props
  const manipulatedContents = [...contents];

  /**
   * TODO: LATER @wilfried
   */
  // if (!isEmpty(reportTechnique.appareil))
  //   insertElement(
  //     manipulatedContents,
  //     manipulatedContents.findIndex(item => item.type === "heading2" && item.children[0].text === "TECHNIQUE:") + 2,
  //     { type: "paragraph", children: [{ text: `Appareil: ${reportTechnique.appareil}` }], align: "left" },
  //   );

  if (!isEmpty(reportTechnique.dose))
    insertElement(
      manipulatedContents,
      manipulatedContents.findIndex(item => item.type === "heading2" && item.children[0].text === "TECHNIQUE:") + 1,
      { type: "paragraph", children: [{ text: `Dose:  ${reportTechnique.dose}` }], align: "left" },
    );

  const elements = manipulatedContents.map(item => {
    switch (item.type) {
      case "heading1":
        return (
          <Text key={item.type} style={{ ...styles.heading1, textAlign: "center" }}>
            {item.children.map(child => (
              <Text
                key={child.text}
                style={{
                  fontWeight: child.bold ? "bold" : "light",
                  fontStyle: child.italic ? "italic" : "normal",
                }}
              >
                {child.text}
              </Text>
            ))}
          </Text>
        );
      case "heading2":
        return (
          <View key={item.type} style={{ ...styles.heading2, textAlign: item.align }}>
            <Text key={item.type} style={styles.heading2}>
              {item.children.map(child => (
                <Text
                  key={child.text}
                  style={{
                    fontWeight: child.bold ? "bold" : "light",
                    fontStyle: child.italic ? "italic" : "normal",
                  }}
                >
                  {child.text}
                </Text>
              ))}
            </Text>
          </View>
        );
      case "paragraph":
        return (
          <View key={item.type} style={{ ...styles.paragraph, textAlign: item.align }}>
            <Text key={item.type} style={styles.paragraph}>
              {item.children.map(child => (
                <Text
                  key={child.text}
                  style={{
                    fontWeight: child.bold ? "bold" : "light",
                    fontStyle: child.italic ? "italic" : "normal",
                  }}
                >
                  {child.text}
                </Text>
              ))}
            </Text>
          </View>
        );
      case "table":
        return item.children.map(tableRow => (
          <TableRow
            key={tableRow.children[0].type}
            child1={tableRow.children[0].children[0].text}
            child2={tableRow.children[1].children[0].text}
          />
        ));
      default:
        return null;
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.watermark} fixed>
          PRELIMINARY
        </Text>
        {pdfImageUrl && <Image src={pdfImageUrl} style={styles.backgroundImage} fixed />}
        <View style={styles.header} fixed />
        <View style={styles.body}>
          {elements}
          <View key="radiologistDoctorName" style={{ textAlign: "right", bottom: -30 }}>
            {!isEmpty(doctorInfo.radiologistDoctor) && (
              <Text style={styles.radiologistDoctorName}>{doctorInfo.radiologistDoctor}</Text>
            )}
            {/* {reportDates.dateOfValidation && (
              <Text style={styles.dateOfValidation}>
                Compte-rendu validé le: &nbsp;
                {reportDates.dateOfValidation.format("DD/MM/YYYY")}
              </Text>
            )} */}
            {/* {doctorInfo.validationDoctor && (
              <Text style={styles.validationDoctorName}>{doctorInfo.validationDoctor}</Text>
            )} */}
          </View>
        </View>
        <View style={styles.footer} fixed />
      </Page>
    </Document>
  );
};

export default PDF;
