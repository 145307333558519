import { DefaultTheme } from "styled-components";

export const styledMilvueTheme: DefaultTheme = {
  colors: {
    primary: {
      main: "#4ED3B4",
    },
    secondary: {
      main: "#9577FF",
    },
    text: {
      main: "#F4F4F4",
    },
    bg: {
      main: "#24303E",
      light: "#334355",
    },
  },
};
