import { RcFile } from "antd/es/upload";
import { Editor as SlateEditor, Transforms, Text, Node } from "slate";
import { CustomEditor, HeadingOneElement, HeadingTwoElement, ParagraphElement, TextAlign } from "types/CustomTypes";
import { TemplateState } from "types/template";

const isBoldActive = (editor: CustomEditor) => {
  const [match] = Array.from(
    SlateEditor.nodes(editor, {
      // FIXME: I don't have any props type
      match: (n: any) => n.bold === true,
      universal: true,
    }),
  );

  return !!match;
};

export const toggleBold = (editor: CustomEditor) => {
  const isActive = isBoldActive(editor);
  Transforms.setNodes(editor, { bold: !isActive }, { match: n => Text.isText(n), split: true });
};

const isItalicActive = (editor: CustomEditor) => {
  const [match] = Array.from(
    SlateEditor.nodes(editor, {
      // FIXME: I don't have any props type
      match: (n: any) => n.italic === true,
      universal: true,
    }),
  );

  return !!match;
};

export const toggleItalic = (editor: CustomEditor) => {
  const isActive = isItalicActive(editor);
  Transforms.setNodes(editor, { italic: !isActive }, { match: n => Text.isText(n), split: true });
};

const isBlockActive = (editor: CustomEditor, alignment: string) => {
  const [match] = Array.from(
    SlateEditor.nodes(editor, {
      match: n => Node.isNode(n) && (n as ParagraphElement | HeadingOneElement | HeadingTwoElement).align === alignment,
    }),
  );

  return !!match;
};

export const toggleBlockAlignment = (editor: CustomEditor, alignment: TextAlign) => {
  const isActive = isBlockActive(editor, alignment);
  Transforms.setNodes(editor, { align: isActive ? undefined : alignment });
};

export type IDispatchCovertPdf2ImageProps = {
  pdfTemplate: RcFile;
  authToken: string;
  updateTemplateState: (newReportValues: TemplateState) => void;
  templateState: TemplateState;
};

export const isEmpty = (property: any) => String(property).trim().length === 0;
