import { Outlet, Navigate, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/auth-context";
import { useEffect } from "react";
import Spinner from "components/Shared/spinner";
import styled from "styled-components";
import { Alert } from "antd";

const LoadingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.main};
  flex: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
`;

const ProtectedRoutes = () => {
  const location = useLocation();
  const { isAuthenticated, login, loading, error } = useAuthContext();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      login({ token }).then(() => {
        const language = searchParams.get("language");
        let newPath = location.pathname;
        if (language !== null) {
          newPath += `?language=${language}`;
        }
        navigate(newPath, { replace: true });
      });
    }
  }, [token, login]);

  if (error) {
    return (
      <LoadingContainer>
        <Alert message="Error Text" description="Something is not right please try again later!" type="error" />
      </LoadingContainer>
    );
  }

  if (token || loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoutes;
