import { Editable, RenderElementProps, RenderLeafProps } from "slate-react";
import { Divider, Layout, message } from "antd";
import { CustomEditor, Descendant } from "types/CustomTypes";
import styled from "styled-components";
import ToolBar from "./ToolBar";
import { useCallback, useRef, useState } from "react";
import TemplateDialog from "components/Template/TemplateDialog";

const { Header, Content } = Layout;

type IEditor = {
  editor: CustomEditor;
  renderElement: (props: RenderElementProps) => JSX.Element;
  renderLeaf: (props: RenderLeafProps) => JSX.Element;
  editorValues: Descendant[];
};

const EditorContainer = styled.div`
  max-width: 210mm;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: "HelveticaNeueRegularMilvue";
`;

const Editor = ({ editor, renderElement, renderLeaf, editorValues }: IEditor) => {
  const [zoom, setZoom] = useState(100);
  const [copied, setCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleZoom = useCallback((delta: number) => setZoom(z => z + delta), [setZoom]);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleCopy = async () => {
    if (!contentRef.current) return;
    let htmlContent = contentRef.current.innerHTML;
    htmlContent = htmlContent.replace(/• /g, "<br/>• ");

    if ("clipboard" in navigator && window.isSecureContext) {
      try {
        const item = new ClipboardItem({
          "text/plain": new Blob([htmlContent], { type: "text/plain" }),
          "text/html": new Blob([htmlContent], { type: "text/html" }),
        });

        await navigator.clipboard.write([item]);
        setCopied(true);
        message.success("Copied successfully!");

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (err) {
        setCopied(false);
        console.error(err);
      }
    }
  };

  if (editorValues.length === 0) {
    return <Divider />;
  }

  return (
    <>
      <Layout style={{ height: "100%", width: "100%" }}>
        <Header style={{ backgroundColor: "#24303E", height: "auto" }}>
          <ToolBar
            editor={editor}
            editorValues={editorValues}
            handleZoom={handleZoom}
            handleCopy={handleCopy}
            setIsOpen={setIsOpen}
            copied={copied}
          />
        </Header>
        <Content style={{ overflow: "auto" }}>
          <EditorContainer ref={contentRef} style={{ zoom: `${zoom}%` }}>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              style={{
                lineHeight: 1.5,
                fontFamily: "HelveticaNeueRegularMilvue",
              }}
            />
          </EditorContainer>
        </Content>
      </Layout>
      <TemplateDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Editor;
