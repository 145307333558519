import { RenderElementProps } from "slate-react";

const EditorElement = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case "heading1":
      return (
        <h1 {...attributes} style={{ fontSize: 24, textAlign: element.align ?? "center" }}>
          {children}
        </h1>
      );
    case "heading2":
      return (
        <h2 {...attributes} style={{ fontSize: 20, textAlign: element.align, marginTop: 16 }}>
          {children}
        </h2>
      );
    case "table":
      return (
        <table style={{ display: "flex", flexDirection: "column", width: "100%", border: 0 }}>
          <tbody {...attributes}>{children}</tbody>
        </table>
      );
    case "table-row":
      return (
        <tr {...attributes} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          {children}
        </tr>
      );
    case "table-cell":
      return (
        <td
          {...attributes}
          style={{
            flex: "1",
            border: "none",
            padding: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </td>
      );
    case "paragraph":
      return (
        <p {...attributes} style={{ fontSize: 14, textAlign: element.align, marginBottom: "2px" }}>
          {children}
        </p>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default EditorElement;
