import "antd/dist/reset.css";
import AntdProvider from "./providers/AntdProvider";
import RoutesProvider from "./RoutesProvider";
import { AuthContextProvider } from "./contexts/auth-context";
import { TemplateContextProvider } from "contexts/template-context";
import { ReportContextProvider } from "contexts/report-context";

function App() {
  return (
    <AntdProvider>
      <AuthContextProvider>
        <ReportContextProvider>
          <TemplateContextProvider>
            <RoutesProvider />
          </TemplateContextProvider>
        </ReportContextProvider>
      </AuthContextProvider>
    </AntdProvider>
  );
}

export default App;
