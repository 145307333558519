import { Card, Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { User } from "../types/users";
import { useAuthContext } from "../contexts/auth-context";

const Login = () => {
  const { login, loading } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleFinish = async (values: User) => {
    await login({ token: values.token });
    navigate(location.state?.from?.pathname || "/", { replace: true });
  };

  return (
    <Card title="Milvue Login" hoverable style={{ maxWidth: "30em", width: "100%" }}>
      <Form size="large" layout="horizontal" name="reportor-login-form" onFinish={handleFinish}>
        <Form.Item name="token" rules={[{ required: true, message: "Please input your token!" }]}>
          <Input prefix={<UserOutlined />} type="text" placeholder="Token" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" role="button" htmlType="submit" disabled={loading} style={{ width: "100%" }}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Login;
