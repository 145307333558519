import { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../contexts/auth-context";
import dayjs from "dayjs";
import { PatientSex } from "types/ReportInfo";

type IStudyApi = {
  studyId: string;
};

type IStudyResponse = {
  images: string[];
  patientAge: string;
  patientSex: PatientSex;
  dateOfExam: dayjs.Dayjs;
};

const makeDate = (date: string) => {
  if (date) {
    const parsedDate = dayjs(date, "YYYY-MM-DD", true);
    if (parsedDate.isValid()) {
      return parsedDate;
    }
    console.error(`Invalid date format: ${date}`);
  }
  return dayjs();
};

const useStudyApi = ({ studyId }: IStudyApi): IStudyResponse => {
  const { authToken } = useAuthContext();
  const [studyResponse, setStudyResponse] = useState<IStudyResponse>({
    dateOfExam: dayjs(),
    images: [],
    patientAge: "",
    patientSex: "M",
  });

  useEffect(() => {
    let isAreadyfetched = false;

    const fetchStudyResponse = async () => {
      const response = await axios.get(`/api/study/${studyId}`, {
        headers: {
          Authorization: authToken,
        },
      });

      if (!isAreadyfetched) {
        setStudyResponse({
          images: response.data.image_urls,
          dateOfExam: makeDate(response.data.exam_date),
          patientAge: response.data.patient_age,
          patientSex: response.data.patient_sex,
        });
      }
    };
    try {
      fetchStudyResponse();
    } catch (e) {
      throw new Error("Unable to get study response");
    }

    return () => {
      isAreadyfetched = true;
    };
  }, [studyId]);

  return {
    ...studyResponse,
  };
};

export default useStudyApi;
