/* eslint-disable @typescript-eslint/naming-convention */
import axios from "axios";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PostSettingsResponse, TemplateState } from "types/template";
import { useAuthContext } from "./auth-context";
import { useReportContext } from "./report-context";

const initialTemplateState: TemplateState = {
  pdfInfo: {
    pdfImageUrl: "",
  },
  layoutSettings: {
    header: 70,
    footer: 50,
    margin: 30,
  },
  defaultSettings: {
    radiologistName: "",
    pacsIpAddress: "",
    pacsPort: 0,
  },
};

interface TemplateContextType {
  templateState: TemplateState;
  updateTemplateState: (newReportValues: TemplateState) => void;
}

const TemplateContext = React.createContext<TemplateContextType | undefined>(undefined);

type TemplateContextProviderProps = {
  children: React.ReactNode;
};

const TemplateContextProvider = ({ children }: TemplateContextProviderProps) => {
  const { authToken } = useAuthContext();
  const { updateReportState, reportState } = useReportContext();

  const [templateState, setTemplateState] = useState<TemplateState>(initialTemplateState);

  const updateTemplateState = useCallback(
    (newReportValues: TemplateState) => {
      setTemplateState(newReportValues);
    },
    [setTemplateState],
  );

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const headers = {
          Authorization: authToken,
        };
        const { data } = await axios.get("/api/settings", { headers });
        if (data !== "no settings found") {
          const {
            layout_settings: layoutSettings,
            template,
            default_settings: defaultSettings,
          }: PostSettingsResponse = data;
          const imageUrl = `data:image/png;base64,${template}`;
          updateTemplateState({
            pdfInfo: {
              pdfImageUrl: imageUrl,
            },
            layoutSettings,
            defaultSettings: {
              radiologistName: defaultSettings.radiologist_name,
              pacsIpAddress: defaultSettings.pacs_ip_address,
              pacsPort: defaultSettings.pacs_port,
            },
          });

          updateReportState({
            ...reportState,
            doctorInfo: {
              radiologistDoctor: defaultSettings.radiologist_name,
            },
          });
        }
      } catch (error) {
        console.error("Failed to fetch settings", error);
      }
    };

    if (authToken) {
      fetchSettings();
    }
  }, [updateTemplateState, authToken]);

  const contextValue = useMemo(() => {
    return {
      templateState,
      updateTemplateState,
    };
  }, [templateState, updateTemplateState]);

  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
};

const useTemplateContext = () => {
  const context = useContext(TemplateContext);
  if (context === undefined) {
    throw new Error("useTemplateContext must be used within a TemplateContextProvider.");
  }

  return context;
};

export { TemplateContextProvider, useTemplateContext, TemplateContext };
