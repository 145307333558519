import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification/interface";
import React from "react";

export type IOpenNotificationProps = {
  message: string;
  description: string;
};

type IUseNotification = {
  openNotification: ({ message, description }: IOpenNotificationProps) => void;
  notificationContextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

type NotificationType = "success" | "info" | "warning" | "error";

type IUseNotificationState = {
  placement: NotificationPlacement;
  notificationType: NotificationType;
};

const useNotification = ({ placement, notificationType }: IUseNotificationState): IUseNotification => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({ message, description }: IOpenNotificationProps) => {
    api[notificationType]({
      message,
      description,
      placement,
    });
  };

  return {
    openNotification,
    notificationContextHolder: contextHolder,
  };
};

export default useNotification;
