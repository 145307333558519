import {
  BoldOutlined,
  DownloadOutlined,
  ItalicOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlignCenterOutlined,
  SendOutlined,
  PrinterOutlined,
  CopyOutlined,
  CheckCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Divider, Modal } from "antd";
import { pdf } from "@react-pdf/renderer";
import styled from "styled-components";
import PDF from "./pdf";
import { CustomEditor, Descendant } from "types/CustomTypes";

import { toggleBold, toggleBlockAlignment, toggleItalic } from "utils/toolBarUtils";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "contexts/auth-context";
import { useParams } from "react-router-dom";
import { useReportContext } from "contexts/report-context";
import { ReportState } from "types/ReportInfo";
import { isFirefox } from "utils/platform";
import useNotification from "hooks/useNotification";
import { useTemplateContext } from "contexts/template-context";
import { TemplateState } from "types/template";

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg.main};
  gap: 1em;
  flex-wrap: wrap;
  padding-bottom: 6px;
`;

type IToolbar = {
  editor: CustomEditor;
  editorValues: Descendant[];
  handleZoom: (delta: number) => void;
  handleCopy: () => Promise<void>;
  copied: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type IToolbarWithCustomDownloadHandler = IToolbar & {
  onDownloadHandler: (editorValues: Descendant[], templateState: TemplateState) => void;
  handlePrint: () => Promise<void>;
  handleSend: () => Promise<void>;
  templateState: TemplateState;
};

interface Report {
  title: string;
  sections: {
    heading: string;
    text?: string;
  }[];
}

const getPdfBlobUrl = async (editorValues: Descendant[], reportState: ReportState, templateState: TemplateState) => {
  const blob = pdf(<PDF contents={editorValues} reportState={reportState} templateState={templateState} />).toBlob();

  const data = await blob;
  const blobUrl = URL.createObjectURL(data);

  return { blobUrl };
};

export const ToolbarWithCustomHandler = ({
  editor,
  editorValues,
  handleZoom,
  handleCopy,
  onDownloadHandler,
  handlePrint,
  handleSend,
  copied,
  setIsOpen,
  templateState,
}: IToolbarWithCustomDownloadHandler) => {
  const isFirefoxBrowser = isFirefox();

  const { openNotification, notificationContextHolder } = useNotification({
    notificationType: "warning",
    placement: "top",
  });
  const onBoldHandler = () => {
    toggleBold(editor);
  };

  const handleItalic = () => {
    toggleItalic(editor);
  };

  useEffect(() => {
    if (isFirefoxBrowser) {
      openNotification({
        message: "Browser Compatibility",
        description:
          "ZoomIn, ZoomOut, and CopyAll tools are currently unavailable in Firefox Browser. We apologize for any inconvenience caused.",
      });
    }
  }, []);
  return (
    <>
      {notificationContextHolder}
      <ToolbarContainer data-testid="toolbar">
        <Button role="button" name="boldBtn" icon={<BoldOutlined />} size="small" onClick={onBoldHandler} />
        <Button role="button" name="italicBtn" icon={<ItalicOutlined />} size="small" onClick={handleItalic} />
        <Button
          role="button"
          name="alignLeftBtn"
          icon={<AlignLeftOutlined />}
          size="small"
          onClick={() => toggleBlockAlignment(editor, "left")}
        />
        <Button
          role="button"
          name="alignCenterBtn"
          icon={<AlignCenterOutlined />}
          size="small"
          onClick={() => toggleBlockAlignment(editor, "center")}
        />
        <Button
          role="button"
          name="alignRightBtn"
          icon={<AlignRightOutlined />}
          size="small"
          onClick={() => toggleBlockAlignment(editor, "right")}
        />

        <Divider type="vertical" />

        <Button
          role="button"
          name="zoomOutBtn"
          disabled={isFirefoxBrowser}
          icon={<ZoomOutOutlined />}
          size="small"
          onClick={() => handleZoom(-10)}
        />
        <Button
          role="button"
          name="zoomInBtn"
          disabled={isFirefoxBrowser}
          icon={<ZoomInOutlined />}
          size="small"
          onClick={() => handleZoom(10)}
        />
        <Divider type="vertical" />
        <Button
          role="button"
          name="copyAllBtn"
          disabled={isFirefoxBrowser}
          icon={!copied ? <CopyOutlined /> : <CheckCircleOutlined style={{ color: "#4ED3B4" }} />}
          size="small"
          onClick={handleCopy}
        />
        <Button role="button" name="printerBtn" icon={<PrinterOutlined />} size="small" onClick={handlePrint} />
        <Button
          role="button"
          name="downloadBtn"
          icon={<DownloadOutlined />}
          size="small"
          onClick={() => onDownloadHandler(editorValues, templateState)}
        />
        <Button role="button" name="sendBtn" icon={<SendOutlined />} size="small" onClick={handleSend} />
        <Divider type="vertical" />
        <Button
          role="button"
          name="settingBtn"
          icon={<SettingOutlined />}
          size="small"
          onClick={() => setIsOpen(true)}
        />
      </ToolbarContainer>
    </>
  );
};

export const onDownloadHandler = async (
  editorValues: Descendant[],
  reportState: ReportState,
  templateState: TemplateState,
) => {
  const { blobUrl } = await getPdfBlobUrl(editorValues, reportState, templateState);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = "report.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ToolBar = ({ editor, editorValues, handleZoom, handleCopy, copied, setIsOpen }: IToolbar) => {
  const { templateState } = useTemplateContext();
  const { reportState } = useReportContext();

  const [isPdfDialogOpen, setPdfDialogOpen] = useState(false);

  const [blobUrl, setblobUrl] = useState("");
  const [isSendButton, setIsSendButton] = useState(false);

  const { authToken } = useAuthContext();
  const { studyId } = useParams() as { studyId: string };

  const handlePrint = async () => {
    const { blobUrl: pdfBlobUrl } = await getPdfBlobUrl(editorValues, reportState, templateState);

    setblobUrl(pdfBlobUrl);
    setPdfDialogOpen(true);
  };

  const handleSend = async () => {
    const { blobUrl: pdfBlobUrl } = await getPdfBlobUrl(editorValues, reportState, templateState);
    setblobUrl(`${pdfBlobUrl}#toolbar=0`); // "#toolbar=0" for chrome to hide the toolbar
    setIsSendButton(true);
    setPdfDialogOpen(true);
  };

  const handleConfirmSend = async () => {
    const reportValues = editorValues.reduce(
      (acc, v) => {
        if (v.type === "heading1") {
          acc.title = v.children.map(c => c.text).join(" ");
          return acc;
        }
        if (v.type === "heading2") {
          acc.sections.push({ heading: v.children.map(c => c.text).join(" ") });
          return acc;
        }
        if (v.type === "paragraph") {
          const previous = acc.sections[acc.sections.length - 1].text
            ? `${acc.sections[acc.sections.length - 1].text}\n`
            : "";
          acc.sections[acc.sections.length - 1].text = `${previous}${v.children.map(c => c.text).join("\n")}`;
          return acc;
        }
        return acc;
      },
      { title: "", sections: [] } as Report,
    );

    const headers = {
      Authorization: authToken,
    };

    try {
      await axios.post(`/api/send/${studyId}`, reportValues, { headers });
      setPdfDialogOpen(false);
      setIsSendButton(false);
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  return (
    <>
      <ToolbarWithCustomHandler
        editor={editor}
        editorValues={editorValues}
        handleZoom={handleZoom}
        onDownloadHandler={() => onDownloadHandler(editorValues, reportState, templateState)}
        handleCopy={handleCopy}
        handlePrint={handlePrint}
        handleSend={handleSend}
        copied={copied}
        setIsOpen={setIsOpen}
        templateState={templateState}
      />
      <Modal
        title="PDF Preview"
        open={isPdfDialogOpen}
        width={1000}
        bodyStyle={{ height: "80vh" }}
        onCancel={() => {
          setPdfDialogOpen(false);
          setIsSendButton(false);
        }}
        onOk={handleConfirmSend}
        okButtonProps={{ hidden: !isSendButton }}
        centered
        okText="Confirm"
      >
        <iframe
          title="Print Preview"
          src={blobUrl}
          contentEditable
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Modal>
    </>
  );
};

export default ToolBar;
