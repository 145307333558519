import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAuthContext } from "contexts/auth-context";
import { CompletionResponse, ReportContents } from "types/Report";
import { ChatCompletionResponseMessage } from "types/OpenAiCustomTypes";

const useGpt = (studyId: string) => {
  const { authToken } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reportContents, setReportContents] = useState<ReportContents>();
  const [chatMessages, setChatMessages] = useState<ChatCompletionResponseMessage[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [firstRequest, setFirstRequest] = useState(true);

  const generateResponse = async (newQuestion?: string) => {
    setLoading(true);

    try {
      abortControllerRef.current = new AbortController();

      const messages = newQuestion ? [...chatMessages, { role: "user", content: `${newQuestion}` }] : chatMessages;

      const {
        data: { response, messages: responseMessages },
      } = await axios.post<CompletionResponse>(
        `/api/chat/completions/${studyId}`,
        { messages },
        {
          headers: {
            Authorization: authToken,
          },
          signal: abortControllerRef.current.signal,
        },
      );

      if (response) {
        setLoading(false);
        setError(false);
        setReportContents(response);
        setChatMessages(responseMessages);
        setFirstRequest(false);
        return true;
      }

      return false;
    } catch (e) {
      if (axios.isCancel(e)) {
        setError(false);
      } else {
        setError(true);
      }
      return false;
    }
  };

  useEffect(() => {
    generateResponse();
  }, [studyId]);

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      setLoading(false);
      setError(false);
      abortControllerRef.current.abort();
    }
  };

  return {
    loading,
    error,
    reportContents,
    generateResponse,
    cancelRequest,
    firstRequest,
  };
};

export default useGpt;
