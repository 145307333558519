import ChatSection from "../ChatPanel/chatSection";
import Report from "../ReportPanel/Report";
import Spinner from "../Shared/spinner";
import { Row, Col, Grid, Alert } from "antd";
import useGpt from "../../hooks/useGpt";
import styled from "styled-components";
import ImageGrid from "components/UI/ImageGrid";
import { useParams } from "react-router-dom";
import useStudyApi from "hooks/useStudyApi";
import ReportForm from "components/ReportPanel/ReportForm";
import { useReportContext } from "contexts/report-context";
import { useEffect } from "react";
import dayjs from "dayjs";

const LeftPanelContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.main};
  padding: 8px;
  justify-content: center;
`;

const RightPanelContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ReportContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.main};
  flex: 97%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

const NuanceSpeechKit = styled.div<{ isMobile: boolean }>`
  position: ${props => (props.isMobile ? "static" : "fixed")};
  bottom: ${props => (props.isMobile ? "auto" : "2px")};
  left: ${props => (props.isMobile ? "auto" : "2px")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const { useBreakpoint } = Grid;

const ReportPage = () => {
  const screens = useBreakpoint();
  const isMobile = !screens.md;

  // FIXME: useParams seems to possibly return undefined here, is it possible ?
  const { studyId } = useParams() as { studyId: string };

  const { images, dateOfExam, patientAge, patientSex } = useStudyApi({ studyId });
  const { loading, error, reportContents, generateResponse, cancelRequest, firstRequest } = useGpt(studyId);

  const { updateReportState, reportState } = useReportContext();

  useEffect(() => {
    if (reportContents) {
      updateReportState({
        ...reportState,
        patientInfo: {
          patientAge,
          patientSex,
          ins: "",
          patientName: "",
        },
        reportDates: {
          dateOfExam,
          dateOfEntryOfCCR: dayjs(),
        },
      });
    }
  }, [reportContents, updateReportState, dateOfExam, patientAge, patientSex]);

  const renderReport = () => {
    if (error) {
      return <Alert message="Error Text" description="Something is not right please try again later!" type="error" />;
    }
    if (loading && !error) {
      return <Spinner />;
    }
    if (!reportContents) {
      return null;
    }
    return (
      <>
        <Report reportContents={reportContents} reportState={reportState} />
        <ReportForm initialValues={reportState} />
      </>
    );
  };

  return (
    <Row style={{ height: "100%" }}>
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={6}
        style={{ height: screens.md ? "100%" : "auto", width: "100%", overflow: "auto" }}
      >
        <LeftPanelContainer>
          <ImageGrid images={images} />
        </LeftPanelContainer>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={18}
        xl={18}
        style={{ height: screens.md ? "100%" : "calc(100% - 120px)", width: "100%" }}
      >
        <RightPanelContainer>
          <ReportContainer>{renderReport()}</ReportContainer>
          <ChatSection
            generateResponse={generateResponse}
            firstRequest={firstRequest}
            cancelRequest={cancelRequest}
            loading={loading}
          />
        </RightPanelContainer>
      </Col>
      <NuanceSpeechKit isMobile={isMobile} id="nuance_speech_kit" />
    </Row>
  );
};

export default ReportPage;
